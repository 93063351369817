import { Link, useLocation } from 'react-router-dom';

import Layout from '../components/Layout';

const strings = {
	en: {
		title: 'Privacy Policy',
		title1: 'CONTACT WITH US',
		title2: 'COOKIES',
		title3: 'WEB-ANALYSIS',
		title4: 'NEWSLETTER',
		title5: 'YOUR RIGHTS',
		text1: 'The protection of your personal data is of particular concern to us. We therefore process your data exclusively on the basis of the statutory provisions (DSGVO, TKG 2003). In this data protection information we inform you about the most important aspects of data processing within the framework of our website.',
		text2: 'If you contact us via e-mail, your data will be stored for six months for the purpose of processing the request and in the event of follow-up questions. These data will not be passed on without your consent.',
		text3: 'Our website uses so-called Cookies. These are small text files that are stored on your end device with the help of the browser. They do not cause any damage.\n\nWe use Cookies to make our website user-friendly. Some Cookies remain stored on your end device until you delete them. They enable us to recognise your browser the next time you visit our website.\n\nIf you do not wish Cookies to be used, you can adjust your browser so that it informs you when Cookies are enabled. You can then decide on the exceptional activation of Cookies in individual cases.\n\nPlease note that if you deactivate Cookies, the functionality of our website may be restricted.',
		text4: 'This website uses Google Analytics, a web analytics service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States of America ("Google"). Google Analytics uses Cookies, which are text files placed on your computer, to help the website analyse how users use our website. The information generated by the Cookie about your use of the website will generally be transmitted to and stored by Google on servers in the United States of America. On behalf of the operator of this website, Google will use this information to evaluate your use of the website, to compile reports on website activity, to provide the website operator with other services relating to website activity and internet usage, and to compile reports on performance based on demographic characteristics and interests.\n\nThe IP address transmitted by your browser as part of Google Analytics is not combined with other data from Google. You may refuse the use of Cookies by selecting the appropriate settings on your browser. Please note, however, that in this case you may not be able to use all functions of our website to their full extent. You can also prevent Google from collecting the data generated by the Cookie and related to your use of the website (including your IP address) and from processing this data by downloading and installing the browser plugin available under the following link:\n\n',
		text5: 'You have the possibility to subscribe to our newsletter via our website. To do this, we need your e-mail address and your declaration that you agree to receive the newsletter.\n\nIn order to provide you with targeted information, we also collect and process voluntarily provided information on areas of interest, birthdays, postal codes, etc.',
		text6: 'You have the right to information, correction, deletion, limitation, data transferability, revocation and objection regarding your data stored with us. If you believe that the processing of your data violates the data protection law or your data protection claims have otherwise been violated in any way, you can contact our data protection officer at',
		text7: 'or the Austrian Data Protection Authority.',
	},
	de: {
		title: 'Datenschutz',
		title1: 'KONTAKT MIT UNS',
		title2: 'COOKIES',
		title3: 'WEB-ANALYSE',
		title4: 'NEWSLETTER',
		title5: 'IHRE RECHTE',
		text1: 'Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.',
		text2: 'Wenn Sie per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.',
		text3: 'Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.\n\nWir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch auf unserer Website wiederzuerkennen.\n\nWenn Sie die Verwendung von Cookies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert. Sie können dann im Einzelfall können über die ausnahmsweise Aktivierung von Cookies entscheiden.\n\nBitte beachten Sie, dass bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.',
		text4: 'Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung unserer Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen, um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen und um Berichte zur Leistung nach demografischen Merkmalen und Interessen zu erstellen.\n\nDie im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Bitte beachten Sie jedoch, dass Sie in diesem Fall möglicherweise nicht alle Funktionen unserer Website vollumfänglich nutzen können. Sie können darüber hinaus die Erfassung der durch Cookies erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:\n\n',
		text5: 'Sie haben die Möglichkeit, über unsere Website unseren Newsletter zu abonnieren. Hierfür benötigen wir Ihre E-Mail-Adresse und ihre Erklärung, dass Sie mit dem Bezug des Newsletters einverstanden sind.\n\nUm Sie zielgerichtet mit Informationen zu versorgen, erheben und verarbeiten wir außerdem freiwillig gemachte Angaben zu Interessengebieten, Geburtstag, Postleitzahl, etc.',
		text6: 'Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an unseren Datenschutzbeauftragten unter',
		text7: 'oder an die Datenschutzbehörde wenden.',
	},
};

function Privacy() {
	const { pathname } = useLocation();
	const lang = pathname === '/privacy' ? 'en' : pathname.split('/').pop();
	const data = strings[lang];

  return (
		<Layout>
			<section id="contact" className="bg-gray-100 pt-20 pb-30 text-content font-medium">
				<div className="container mx-auto px-6 lg:px-20 max-w-7xl">
					<div className="pb-24 mb-36 border-b border-gray-800">
						<Link to="/">
							<img src="/images/logo-dark.svg" className="w-42" alt="Logo" />
						</Link>
					</div>

					<div className="flex flex-col lg:flex-row justify-between">
						<div className="w-1/3 tracking-wider text-gray-800 text-lg font-body uppercase mb-5 lg:mb-0">
							<div className="pt-3">
								<Link
									to="/privacy"
									className={`${lang === 'en' ? 'text-blue-500 border-blue-500 border-b' : 'text-gray-800 border-transparent hover:border-gray-600'} pb-px capitalize mr-14 hover:opacity-75`}
								>
									English
								</Link>
								<Link
									to="/privacy/de"
									className={`${lang === 'de' ? 'text-blue-500 border-blue-500 border-b' : 'text-gray-800 border-transparent hover:border-gray-600'} pb-px capitalize mr-14 hover:opacity-75`}
								>
									Deutsch
								</Link>
							</div>
						</div>
						<div className="w-2/3 font-light text-lg mb-16 font-sans">
							<h1 className="text-gray-800 font-medium text-5xl md:text-6.5xl mb-24 leading-normal">
								{data.title}
							</h1>
            	{data.text1}
    				</div>
					</div>

					<div className="flex flex-col lg:flex-row justify-between whitespace-pre-line">
						<div className="w-1/3 tracking-wider text-gray-800 text-lg font-body uppercase mb-5 lg:mb-0">
        			{data.title1}
    				</div>
						<div className="w-2/3 font-light text-lg mb-16 font-sans">
							{data.text2}
    				</div>
					</div>
					
					<div className="flex flex-col lg:flex-row justify-between whitespace-pre-line">
						<div className="w-1/3 tracking-wider text-gray-800 text-lg font-body uppercase mb-5 lg:mb-0">
							{data.title2}
    				</div>
						<div className="w-2/3 font-light text-lg mb-16 font-sans">
							{data.text3}
    				</div>
					</div>
					
					<div className="flex flex-col lg:flex-row justify-between whitespace-pre-line">
						<div className="w-1/3 tracking-wider text-gray-800 text-lg font-body uppercase mb-5 lg:mb-0">
							{data.title3}
    				</div>
						<div className="w-2/3 font-light text-lg mb-16 font-sans">
							{data.text4}
							<a href="http://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" className="text-blue-500 border-b border-transparent pb-px hover:border-blue-500" rel="noreferrer">
								http://tools.google.com/dlpage/gaoptout?hl=en
							</a>
						</div>
					</div>

					<div className="flex flex-col lg:flex-row justify-between whitespace-pre-line">
						<div className="w-1/3 tracking-wider text-gray-800 text-lg font-body uppercase mb-5 lg:mb-0">
							{data.title4}
    				</div>
						<div className="w-2/3 font-light text-lg mb-16 font-sans">
							{data.text5}
    				</div>
					</div>
					
					<div className="flex flex-col lg:flex-row justify-between whitespace-pre-line">
						<div className="w-1/3 tracking-wider text-gray-800 text-lg font-body uppercase mb-5 lg:mb-0">
							{data.title5}
    				</div>
						<div className="w-2/3 font-light text-lg mb-16 font-sans">
							{data.text6}
							&nbsp;<a href="mailto:privacy@prysym.com" target="_blank" className="text-blue-500 border-b border-transparent pb-px hover:border-blue-500" rel="noreferrer">privacy@prysym.com</a>&nbsp;
							{data.text7}
    				</div>
					</div>
				</div>
			</section>
		</Layout>
  );
}

export default Privacy;
