import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-gray-800 py-20">
			<div className="container mx-auto px-6 lg:px-20 max-w-7xl">
				<div className="flex flex-col md:flex-row justify-between">
					<p className="uppercase font-sans font-medium text-lg tracking-widest text-gray-200 md:mb-14">
            We'd love to hear from you
          </p>
					<div className="text-gray-200 text-lg font-sans tracking-wider flex flex-col md:items-end my-5 md:my-0 font-medium">
						<a target="_blank" href="https://developers.prysym.com/" className="mb-3 hover:opacity-75" rel="noreferrer">
							Documentation
						</a>
						<Link to="/privacy" className="hover:opacity-75">
							Privacy Policy
						</Link>
					</div>
				</div>

				<a href="mailto:sales@prysym.com" className="text-gray-500 text-3xl lg:text-6xl inline-block hover:text-gray-200">
					sales@prysym.com
				</a>

				<div className="flex flex-col md:flex-row md:justify-between md:items-end">
					<p className="text-gray-500 font-body tracking-wider">
						{new Date().getFullYear()} © Prysym GmbH
					</p>
					<div className="pt-5 md:pt-0 flex flex-col md:justify-end md:items-end">
						<ul className="text-gray-500 mb-6 text-lg font-medium">
							<li className="md:text-right">Prysym GmbH</li>
							<li className="md:text-right">Am Heumarkt 10</li>
							<li className="md:text-right">1030 Wien, Austria</li>
						</ul>
						<div>
							<div className="flex md:justify-end items-end flex-grow">
								<a href="https://www.linkedin.com/company/prysym/" target="_blank" className="mr-6 md:ml-6 md:mr-0 inline-block hover:opacity-75" rel="noreferrer">
									<img src="/images/icon-linked-in.svg" className="w-8" alt="Linkedin" />
								</a>
								<a href="https://www.facebook.com/Prysym-Payment-Technologies-338414503766069/" target="_blank" rel="noreferrer" className="mr-6 md:ml-6 md:mr-0 inline-block hover:opacity-75">
									<img src="/images/icon-facebook.svg" className="w-8" alt="Facebook" />
								</a>
								<a href="https://twitter.com/PrysymPayments" target="_blank" className="mr-6 md:ml-6 md:mr-0 inline-block hover:opacity-75" rel="noreferrer">
									<img src="/images/icon-twitter.svg" className="w-8" alt="Twitter" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
	</footer>
  );
}

export default Footer;
