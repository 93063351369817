
import Footer from './Footer';

function Layout({ children }) {
  return (
    <>
			{children}
			<Footer />
    </>
  );
}

export default Layout;
