function PageNotFound() {
  return (
    <div className="flex items-center justify-center h-full">
			<div className="border-r-2 border-gray-500 text-[26px] px-[15px]">
				404
			</div>
			<div className="p-[10px] text-[18px]">
				Not Found            
			</div>
		</div>
  );
}

export default PageNotFound;
