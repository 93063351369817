import { Routes, Route } from 'react-router-dom';

import CookiesBanner from './components/CookiesBanner';
import PageNotFound from './pages/PageNotFound';
import Privacy from './pages/Privacy';
import Home from './pages/Home';

function App() {
  return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="privacy" element={<Privacy />} />
				<Route path="privacy/de" element={<Privacy />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
			<CookiesBanner />
		</>
  );
}

export default App;
