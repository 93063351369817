
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const cookieName = 'consent';

function CookiesBanner() {
	const [accepted, setAccepted] = useState(true);

	const handleClick = (e) => {
		e.preventDefault();
		Cookies.set(cookieName, true, { expires: 365 });
		setAccepted(true);
	};

	useEffect(() => {
		if (!Cookies.get(cookieName)) {
			setTimeout(() => setAccepted(false), 200);
		}
	}, []);

	if (accepted) {
		return null;
	}

  return (
    <div className="px-5 md:px-10 xl:px-24 py-5 lg:py-12 fixed bottom-0 left-0 w-full bg-black bg-opacity-90">
			<div className="flex items-center flex-wrap">
				<div className="text-white w-full pr-5 lg:pr-0 lg:w-1/2 xl:w-3/5 text-lg mb-5 lg:mb-0">
					This site uses cookies to offer you a better browsing experience.
					<br className="hidden xl:inline-block" />Find out more on
					<a href="/privacy" className="text-pink-500">&nbsp;how we use cookies and how you can change your settings.</a>
				</div>
				<div className="text-white w-full lg:w-1/2 xl:w-2/5 flex lg:justify-end flex-wrap">
					<a
						href="/"
						className="w-full max-w-xs text-center lg:w-auto px-8 py-3 rounded-full text-white border border-white md:mr-7 mb-5 md:mb-0"
						onClick={handleClick}
					>
						I accept cookies
					</a>
				</div>
			</div>
    </div>
  );
}

export default CookiesBanner;
